import * as actionTypes from '../actionTypes.js';

const initialState = {
    cardFromFieldData: null,
    loading: false,
    error: null,
};

const cardFromFieldReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CARD_FROM_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CARD_FROM_FIELD_SUCCESS:
            return {
                ...state,
                loading: false,
                cardFromFieldData: action.payload,
            };
        case actionTypes.FETCH_CARD_FROM_FIELD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_SUCCESS:
            state.cardFromFieldData.data.rows = [...state.cardFromFieldData?.data?.rows, action.payload]
            return {
                ...state,
                loading: false,
                cardFromFieldData: state.cardFromFieldData,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_CARD_FROM_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_CARD_FROM_FIELD_SUCCESS:
            state.cardFromFieldData.data.rows = state.cardFromFieldData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                cardFromFieldData: state.cardFromFieldData
            }

        case actionTypes.UPDATE_CARD_FROM_FIELD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.DELETE_CARD_FROM_FIELD_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_CARD_FROM_FIELD_SUCCESS:
            state.cardFromFieldData.data.rows = state.cardFromFieldData?.data?.rows.filter(card => card.id !== action.payload );
            return{
                ...state,
                loading: false,
                cardFromFieldData: state.cardFromFieldData,
            }
        case actionTypes.DELETE_CARD_FROM_FIELD_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
};
export default cardFromFieldReducer;