import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogIn, verifyOtp } from '../../redux/actions/authActions';
import { Box, Container, TextField, Typography, Paper, InputAdornment, Link, Grid } from '@mui/material';
import LoadingButton from '../../components/buttons/Button';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import WelcomeImage from "../../assets/images/welcome.png";

const initialData = {
    username: "",
    password: "",
    otp: ""
};

function AuthPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState(initialData);
    const [loginLoading, setLoginLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [showOtpForm, setShowOtpForm] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value, formData);
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        console.log(formData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { username, password } = formData;

        await dispatch(LogIn({ username, password }, navigate, setLoginLoading))

            .then(() =>{ 
                setShowOtpForm(true);
                setLoginLoading(false);

            });
    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        console.log(formData);
        const { username, otp } = formData;

        await dispatch(verifyOtp({ username, otp: Number(otp) }, navigate, setOtpLoading))
            .then(() => {
                setFormData(initialData);

            });
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box sx={{
                backgroundColor: 'white', padding: '25px',
                margin: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                border: '1px solid rgba(0,0,0,0.1)'
            }} maxWidth='1000px' >
                <Grid container direction="row">
                    <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' }, margin: '0', padding: '0' }}>
                        <Box
                            component="img"
                            src={WelcomeImage}
                            alt="Login Image"
                            sx={{ width: '100%', height: '100%', objectFit: 'fill', margin: '0', padding: '0' }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ padding: '25px' }}>

                        <Typography variant="h4" textAlign="center" gutterBottom>
                            Login
                        </Typography>
                        <Typography variant="h7" textAlign="center" gutterBottom>
                            Please enter your username and password
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={3} sx={{ paddingTop: '30px' }}>
                            <TextField variant="outlined" label="Username or Email" name="username" placeholder="Username or Email" value={formData.username}
                                onChange={handleChange} size="small" fullWidth InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                    style: { borderColor: '#000000' }
                                }}
                            />
                            <TextField variant="outlined" label="Password" name="password" type="password" placeholder="Password" value={formData.password}
                                onChange={handleChange} size="small" fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    style: { borderColor: '#000000' }
                                }}
                            />
                            <Box textAlign="right">
                                <Typography variant="caption">Forget Password? Contact Admin</Typography>
                            </Box>
                            <LoadingButton variant="contained" loading={loginLoading} type="submit" fullWidth sx={{
                                borderColor: 'green', color: 'white', backgroundColor: 'green',
                                '&:hover': {
                                    backgroundColor: 'darkgreen'
                                }
                            }}
                            >
                                Login
                            </LoadingButton>

                            {showOtpForm && (
                                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                                    <TextField variant="outlined" label="OTP" name="otp" placeholder="Enter OTP" value={formData.otp} onChange={handleChange} size="small" fullWidth />
                                    <LoadingButton variant="contained" loading={otpLoading} onClick={handleOtpSubmit} fullWidth>
                                        Verify OTP
                                    </LoadingButton>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default AuthPage;
