import * as actionTypes from '../actionTypes.js';


const initialState = {
    cardGroupData: null,
    loading: false,
    error: null,
};

const cardGroupReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_CARD_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CARD_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                cardGroupData: action.payload,
            };
        case actionTypes.FETCH_CARD_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARD_GROUP_REQUEST: 
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARD_GROUP_SUCCESS:
            state.cardGroupData.data.rows= [...state.cardGroupData?.data?.rows, action.payload]
            return {
                ...state,
                loading: false,
                cardGroupData: state.cardGroupData
            };
        case actionTypes.CREATE_CARD_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.EDIT_CARD_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.EDIT_CARD_GROUP_SUCCESS:
            state.cardGroupData.data.rows= state.cardGroupData?.data?.rows.map(card => 
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                cardGroupData: state.cardGroupData,
            };
        case actionTypes.EDIT_CARD_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.DELETE_CARD_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_CARD_GROUP_SUCCESS:
            state.cardGroupData.data.rows = state.cardGroupData?.data?.rows.filter(card => card.id !== action.payload)
            return {
                ...state,
                loading: false,
                cardGroupData: state.cardGroupData
            };
        case actionTypes.DELETE_CARD_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: 
            return state;
    }
};

export default cardGroupReducer;