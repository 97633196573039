import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Typography, Box, Link, MenuItem, Select, FormControl,
    CardMedia,
    Dialog,
    DialogContent,
    IconButton,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/system';
import { fetchDownloadsData } from '../../redux/actions/downloadActions';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const RedTableCell = styled(TableCell)({
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#f4f4f4',
    },
}));

const MyTable = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(50);



    useEffect(() => {
        fetchDownloadsData(page, limit);
    }, [page]);

    const handlePageChange = async (event, value) => {
        await setPage(value);
        let rsp = await dispatch(fetchDownloadsData(value, limit));
    };

    const handleRowsPerPageChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(1);
    };

    // Calculate the range of displayed items
    const startItem = (page - 1) * limit + 1;
    const endItem = Math.min(page * limit, totalPages);

    // fetch Downloads data 
    const downloads = useSelector((state) => state?.download?.downloadsData?.data);
    console.log("download", downloads);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchDownloads = async () => {
            setLoading(true);
            let rsp = await dispatch(fetchDownloadsData(page, limit));
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };

        fetchDownloads();
    }, [dispatch]);

    const handleOpenClick = (imageUrl, stage) => {
        if (stage === 'completed') {
            window.open(imageUrl, '_blank');
        }
    };
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    // Function to open the dialog with the selected image
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenDialog(true);
    };

    const handleVideoClick = (videoUrl) => {
        setSelectedImage(videoUrl);
        setOpenDialog(true);
    };

    // Function to close the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedImage('');
    };

    const [tooltipText, setTooltipText] = useState("Copy");

    const handleCopy = (row) => {
        navigator.clipboard.writeText(row.itemId).then(() => {
            setTooltipText("Copied!");
            setTimeout(() => setTooltipText("Copy"), 2000); // Reset tooltip after 2 seconds
        });
    };

    return (
        <>
            <Box sx={{ padding: '16px' }}>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
                    <FormControl variant="outlined" size="small" sx={{ marginRight: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography variant="body2" component="span" sx={{ marginRight: 1, color: '#9e9e9e' }}>Items per page:</Typography>
                            <Select
                                value={limit}
                                onChange={handleRowsPerPageChange}
                                sx={{ width: '70px', height: '20px', color: '#9e9e9e' }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </Box>

                    </FormControl>

                    {/* Pagination */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ marginRight: 2, color: '#9e9e9e' }}>
                            {startItem} - {endItem} of {totalPages}
                        </Typography>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                            size="small"
                        />
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{}}>
                                <TableCell sx={{ fontWeight: 'bold' }}>%</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Stage</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Retry</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>createdAt</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>File Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>type</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>workerName</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Open</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {downloads?.rows?.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <RedTableCell>{row.progressPercentage}</RedTableCell>
                                    <TableCell>{row.stage}</TableCell>
                                    <TableCell>
                                        <Link href="#" onClick={() => console.log('Retry clicked')}>{row.retry}</Link>
                                    </TableCell>
                                    <TableCell>{row.createdAt}</TableCell>
                                    <TableCell>{row.tenant}</TableCell>
                                    <TableCell sx={{ backgroundColor: '#6200ea' }}>{row.type}</TableCell>
                                    <TableCell>{row.workerName} | 0</TableCell>
                                    <TableCell>
                                        {row.itemId}
                                        <Tooltip title={tooltipText} arrow>
                                            <IconButton onClick={() => handleCopy(row)} size="small" sx={{ ml: 1 }}>
                                                <ContentCopyIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {row?.stage === 'completed' || row?.stage === 'ready' ? (
                                            <>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: '30%', height: '30%', objectFit: 'contain' }}
                                                    image={row?.finalUrl}
                                                    alt="Product image"
                                                    onClick={() => handleImageClick(row?.finalUrl)}
                                                />
                                                {row?.stage === 'ready' && (
                                                    <video
                                                        style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                                                        src={row?.finalUrl}
                                                        controls
                                                        alt="Product video"
                                                        onClick={() => handleVideoClick(row?.videoUrl)}
                                                    />
                                                )}
                                                <Typography></Typography>
                                            </>
                                        ) : (
                                            <Link href="#" onClick={() => handleOpenClick(row?.finalUrl, row?.stage)}>open</Link>
                                        )}

                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
                <DialogContent>
                    {/* Close Button */}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Display the larger image */}
                    <img src={selectedImage} alt="Selected" style={{ width: '100%', height: '100%' }} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default MyTable;