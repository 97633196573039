import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchBundleDataRequest = () => ({
    type: actionTypes.FETCH_BUNDLE_DATA_REQUEST,
});
export const fetchBundleDataSuccess = (data) => ({
    type: actionTypes.FETCH_BUNDLE_DATA_SUCCESS,
    payload: data,
});
export const fetchBundleDataFailure = (error) => ({
    type: actionTypes.FETCH_BUNDLE_DATA_FAILURE,
    payload: error,
});

export const fetchBundleData = (page, limit) => {
    

    return async (dispatch) => {
        
        dispatch(fetchBundleDataRequest());
        try {
            const {data} = await api.getBundles(page, limit);
            dispatch(fetchBundleDataSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchBundleDataFailure(error));
        }
    };
};

export const createBundleData = async (formData) => {
    const {data} = await api.createBundle(formData?.id, formData);
    return data;
}

