import * as api from "../../api/index";

// api.getAllVideoEngineTemplates(1, 10)

export const fetchVideoEngineTemplates = async (page, limit) => {
    console.log("fetchVideoEngineTemplates called page limit", page, limit);
    const response = await api.getAllVideoEngineTemplates(page, limit);
    if (response.data) {
        return response.data;
    } else {
        return {
            status: "offline",
            message: "No data found",
            data: {
                CurrentPage: page,
                Records: [],
                RecordsPerPage: limit,
                Total: 0
            }
        };
    }
};


export const fetchSingleVideoEngineTemplates = async (templateId) => {
    console.log("fetchVideoEngineTemplates called templateId", templateId);
    const response = await api.getSingleVideoEngineTemplates(templateId);
    if (response.data) {
        return response.data;
    } else {
        return {
            status: "offline",
            message: "No data found",
            data: {}
        };
    }
};


export const updateSingleVideoEngineTemplates = async (templateId, form) => {
    console.log("fetchVideoEngineTemplates called templateId", templateId);
    const response = await api.updateSingleVideoEngineTemplates(templateId, form);
    if (response.data) {
        return response.data;
    } else {
        return {
            status: "offline",
            message: "No data found",
            data: {}
        };
    }
};

export const generateSampleOfSingleVideoEngineTemplates = async (templateId, form) => {
    console.log("fetchVideoEngineTemplates called templateId", templateId);
    const response = await api.generateSampleOfSingleVideoEngineTemplates(templateId, form);
    if (response.data) {
        return response.data;
    } else {
        return {
            status: "offline",
            message: "No data found",
            data: {}
        };
    }
};