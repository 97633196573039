import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchTemplateRecordRequest = () => ({
    type: actionTypes.FETCH_TEMPLATE_RECORD_REQUEST,
});

export const fetchTemplateRecordSuccess = (data) => ({
    type: actionTypes.FETCH_TEMPLATE_RECORD_SUCCESS,
    payload: data
});
export const fetchTemplateRecordFailure = (error) => ({
    type: actionTypes.FETCH_TEMPLATE_RECORD_FAILURE,
    payload: error,
});

export const fetchTemplateRecordData = () => {
    return async (dispatch) => {
        dispatch(fetchTemplateRecordRequest());
        try{
            const {data} = await api.fetchTemplateRecords();
            console.log("data in quick action", data);
            dispatch(fetchTemplateRecordSuccess(data));
            return data;

        }catch (error){
            dispatch(fetchTemplateRecordFailure(error));
        }
    };
};