import * as actionTypes from '../actionTypes.js';

const initialState = {
    downloadsData: null,
    loading: false,
    error: null,
};

const downloadReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_DOWNLOAD_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.FETCH_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadsData: action.payload,
            }
        case actionTypes.FETCH_DOWNLOAD_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
};
export default downloadReducer;