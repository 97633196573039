import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Paper, Box, Button, Grid2, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplateRecordData } from '../../redux/actions/quickUploaderActions';

const SimpleDesignUpload = () => {
    const dispatch = useDispatch();

    // fetch template record data 
    const templateRecords = useSelector((state) => state?.templateRecord?.templateRecordData?.data);
    console.log("state", templateRecords);


    useEffect(() => {
        const getTemplateRecords = async () => {
            await dispatch(fetchTemplateRecordData());
        };
        getTemplateRecords();
    }, [])

    // Radio button 
    const [value, setValue] = useState(null);

    const handleRadioButton = (event) => {
        setValue(event.target.value);
    }

    //handle Image and Submit data
    const [templateName, setTemplateName] = useState('');
    const [pageSize, setPageSize] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [templateCategory, setTemplateCategory] = useState('');
    const [templateSubCategory, setTemplateSubCategory] = useState('');
    const [imageURL, setImageURL] = useState(null);



    const handleTempleteRecordSubmit = async (e) => {
        e.preventDefault();

    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setImageURL(url);
            console.log('Image URL:', url);
        }
    };
    return (
        <>
            <Grid container sx={{ marginLeft: -2 }}>
                <Grid item xs={12} sm={8} md={8}>
                    <Paper
                        elevation={3}
                        sx={{
                            maxWidth: '1000px',
                            margin: 'auto',
                            padding: '20px',
                            backgroundColor: '#f7f7f7',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ textAlign: 'left' }}>Auto Upload New Design</Typography>
                                <Typography variant="subtitle2" sx={{ textAlign: 'left', fontWeight: 'bold', color: '#f44336' }}>
                                    Please select Images Carefully, as it will be immediately automatically uploaded.
                                </Typography>
                            </Grid>

                            {/* Template Name and page Size*/}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Template Name"
                                    value={templateName}
                                    variant="standard"
                                    onChange={e => setTemplateName(e.target.value)}
                                    required
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard" required>
                                    <InputLabel>Page Size</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left' }}
                                        value={pageSize}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="category1">Category 1</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* Template Description */}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Template Description"
                                    variant="standard"
                                    rows={4}
                                />
                            </Grid>


                            {/* Template category and Sub category */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Template Category</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                    maxWidth: 400,
                                                    minWidth: 200,
                                                },
                                            },
                                        }}
                                    >
                                        {templateRecords?.Records?.map((records, id) => (
                                            <MenuItem
                                                key={id}
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    fontSize: '0.825rem',
                                                }}
                                            >
                                                {records?.scope} - {records?.name} - ({records?.description})
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Template Sub Category</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="A4">A4</MenuItem>
                                        <MenuItem value="A5">A5</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* Is Occasion Date Applicable */}
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                                    <Typography>Is Occasion Date Applicable *</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup row value={value} onChange={handleRadioButton}>
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                            {/* Upload templete */}
                            {value && <Grid2 item xs={12} sx={{marginLeft: 2}}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="image-upload"
                                    type="file"
                                    onChange={handleImageUpload}
                                />
                                <label htmlFor="image-upload">
                                    <Button variant="contained" component="span">
                                        Choose Files
                                    </Button>
                                </label>
                                {imageURL && (
                                    <Box sx={{marginTop: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="body1" color="initial" >Following Image uploaded successfully!</Typography>
                                        <img src={imageURL} alt="Uploaded Preview" width="200" />
                                    </Box>
                                )}
                            </Grid2> }

                        </Grid>
                    </Paper>
                </Grid>

            </Grid>

        </>
    );
};

export default SimpleDesignUpload;