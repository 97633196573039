import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchCardsRequest = () => ({
    type: actionTypes.FETCH_CARDS_REQUEST,
});
export const fetchCardsSuccess = (data) => ({
    type: actionTypes.FETCH_CARDS_SUCCESS,
    payload: data,
});
export const fetchCardsFailure = (error) => ({
    type: actionTypes.FETCH_CARDS_FAILURE,
    payload: error,
});

export const fetchCardsData = (page, limit, search= '', id) => {
    console.log("group id", id);

    return async (dispatch) => {
        
        dispatch(fetchCardsRequest());
        try {
            const {data} = await api.cards(page, limit,search, id);
            dispatch(fetchCardsSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardsFailure(error));
        }
    };
};


export const sendDuplicateCardRequest = async (card_id) => {
    const {data} = await api.duplicateCard(card_id);
    return data;
};



export const sendTemplateIdRequest = () => ({
    type: actionTypes.SEND_TEMPLATE_ID_REQUEST,
});

export const sendTemplateIdSuccess = (data) => ({
    type: actionTypes.SEND_TEMPLATE_ID_SUCCESS,
    payload: data,
});
export const sendTemplateIdFailure = (error) => ({
    type: actionTypes.SEND_TEMPLATE_ID_FAILURE,
    payload: error,
});

export const sendMapTemplateId = async (formdata) => {
    console.log("form data for template 1", formdata);
   const response = await api.mapTemplateInCard(formdata.card_id, formdata.template_id);
   const successAction = response.data;
   console.log("success data", successAction);
   return successAction;
    
};

export const createCardsRequest = () => ({
    type: actionTypes.CREATE_CARDS_REQUEST,
});
export const createCardsSuccess = (data) => ({
    type: actionTypes.CREATE_CARDS_SUCCESS,
    payload: data
});
export const createCardsFailure = (error) => ({
    type: actionTypes.CREATE_CARDS_FAILURE,
    payload: error,
});

export const createCardsData = (newCardData) => {
    return async (dispatch) => {
        dispatch(createCardsRequest());
        try{
            const {data} = await api.createCards(newCardData);
            dispatch(createCardsSuccess(data));
            return data;
        } catch(error) {
            dispatch(createCardsFailure(error));
        }
    }
};

export const updateCardsRequest = () => ({
    type: actionTypes.UPDATE_CARDS_REQUEST,
});
export const updateCardsSuccess = (data) => ({
    type: actionTypes.UPDATE_CARDS_SUCCESS,
    payload: data,
});
export const updateCardsFailure = (error) => ({
    type: actionTypes.UPDATE_CARDS_FAILURE,
    payload: error,
});

export const updateCardsData = (updateData) => {
    return async (dispatch) => {
        dispatch(updateCardsRequest());
        try{
            const response = await api.updateCards(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updateCardsSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updateCardsFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deleteCardsRequest = () => ({
    type: actionTypes.DELETE_CARDS_REQUEST,
});
export const deleteCardsSuccess = (id) => ({
    type: actionTypes.DELETE_CARDS_SUCCESS,
    payload: id,
});
export const deleteCardsFailure = (error) => ({
    type: actionTypes.DELETE_CARDS_FAILURE,
    payload: error,
});

export const deleteCardsData = (id) => {
    return async (dispatch) => {
        dispatch(deleteCardsRequest());
        try{
            await api.deleteCards(id);
            dispatch(deleteCardsSuccess(id));
        } catch(error) {
            dispatch(deleteCardsFailure(error));
        }
    };
};




