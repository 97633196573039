import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as VideoEngineAction from '../../../redux/actions/videoEngineActions';
import { useNavigate } from 'react-router-dom';
//VideoEngineAction.fetchVideoEngineTemplates(1, 10)
import { toast } from 'react-toastify';

const VideoEngineListTemplates = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("fetching data")
                const response = await VideoEngineAction.fetchVideoEngineTemplates(0, 25);
                console.log("response", response);
                setData(response.data.Records);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const generateSample = async (templateId) => {
        const response = await VideoEngineAction.generateSampleOfSingleVideoEngineTemplates(templateId, {
            "mobileNumber": 2345000001
        });
        toast.success("Sample generated successfully");
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <table>
                <thead>
                    <tr>

                        <th>Category</th>
                        <th>Basic</th>
                        <th>Other</th>

                        <th>Environment</th>
                        <th>Public</th>

                        <th>Sample Thumbnail</th>
                        {/* <th>Sample URL</th> */}

                        <th>Template ID</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(item => {

                        let sampleThumbnail = [];
                        try {
                            sampleThumbnail = JSON.parse(item.sampleThumbnail);
                        } catch (e) {
                            console.error('Error parsing sampleThumbnail:', e);
                        }

                        return (
                            <tr key={item.templateId} style={{ borderBottom: '1px solid black !important' }}>

                                <td>{item.category}</td>
                                <td>
                                    <p>{item.name}</p>
                                    <p style={{
                                        fontSize: '14px',
                                        margin: '0px', padding: '0px',
                                        lineHeight: '1.1rem',
                                        color: 'gray'
                                    }}>{item.description}</p>

                                </td>
                                <td style={{
                                    textAlign: 'left',
                                }}>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Edit Allowed: {item.editAllowed ? 'Yes' : 'No'}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Occasion: {item.occasionDate}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Premium: {item.isPremium ? 'Yes' : 'No'}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Party: {item.partyName}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Scope :{item.scope}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Sequence :{item.sequence}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Size :{item.sizeCategory}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Stage :{item.stage}</p>
                                    <p style={{
                                        fontSize: '14px', margin: '0px',
                                        padding: '0px', lineHeight: '1.1rem'
                                    }}>Tenant :{item.tenant}</p>
                                    {/* <td>{item.isOccasionDateApplicable ? 'Yes' : 'No'}</td> */}
                                </td>
                                <td>{item.environment}</td>
                                <td>{item.isPublic ? 'Yes' : 'No'}</td>
                                <td>
                                    {/* {item.sampleThumbnail} */}
                                    {sampleThumbnail?.length > 0 && (
                                        <img
                                            src={sampleThumbnail[0].url}
                                            alt="Sample Thumbnail"
                                            style={{ width: '100px', height: 'auto' }}
                                        />
                                    )}
                                </td>
                                {/* <td>{item.sampleUrl}</td> */}

                                <td>{item.templateId}</td>
                                <td>
                                    <p>Created: {new Date(item.createdAt).toLocaleString()}</p>
                                    <p>Updated: </p>{new Date(item.updatedAt).toLocaleString()}
                                </td>
                                <td>
                                    <button onClick={() => generateSample( item.templateId)}>Sample Generate</button>
                                    <button onClick={() => navigate(`/video-engine/edit/` + item.templateId)}>Edit</button>
                                    <button onClick={() => navigate(`/video-engine/edit-config/` + item.templateId)}>Edit Config</button>
                                    <button>Delete</button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>

    );
};

export default VideoEngineListTemplates;