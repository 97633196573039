import * as actionTypes from "../actionTypes.js";

const initialState = {
    templateRecordData: null,
    loading: false,
    error: null,
};

const templateRecordReducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_TEMPLATE_RECORD_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_TEMPLATE_RECORD_SUCCESS:
            return{
                ...state,
                templateRecordData: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.FETCH_TEMPLATE_RECORD_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};
export default templateRecordReducer;