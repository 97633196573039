import * as actionTypes from '../actionTypes.js';

const initialState = {
    cardEntryValueData: null,
    loading: false,
    error: null,
};

const cardEntryValueReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CARD_ENTRY_VALUES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CARD_ENTRY_VALUES_SUCCESS:
            return {
                ...state,
                loading: false,
                cardEntryValueData: action.payload,
            };
        case actionTypes.FETCH_CARD_ENTRY_VALUES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARD_ENTRY_VALUES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARD_ENTRY_VALUES_SUCCESS:
            state.cardEntryValueData.data.rows = [...state.cardEntryValueData?.data?.rows, action.payload]
            return {
                ...state,
                loading: false,
                cardEntryValueData: state.cardEntryValueData,
            };
        case actionTypes.CREATE_CARD_ENTRY_VALUES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_CARD_ENTRY_VALUES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_CARD_ENTRY_VALUES_SUCCESS:
            state.cardEntryValueData.data.rows = state.cardEntryValueData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                cardEntryValueData: state.cardEntryValueData
            };
        case actionTypes.UPDATE_CARD_ENTRY_VALUES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.DELETE_CARD_ENTRY_VALUES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_CARD_ENTRY_VALUES_SUCCESS:
            state.cardEntryValueData.data.rows = state.cardEntryValueData?.data?.rows.filter(card => card.id !== action.payload)
            return {
                ...state,
                loading: false,
                cardEntryValueData: state.cardEntryValueData,
            };
        case actionTypes.DELETE_CARD_ENTRY_VALUES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
};
export default cardEntryValueReducer;