import React, { useEffect, useRef, useState } from 'react';
import * as VideoEngineAction from '../../../redux/actions/videoEngineActions';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid, Grid2, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Label } from '@mui/icons-material';
import { toast } from 'react-toastify';

const VideoEngineConfigurationEdit = () => {
    const [configurationData, setConfigurationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageSrcs, setImageSrcs] = useState({});
    const videoRefs = useRef([]);
    const canvasRefs = useRef([]);
    const navigate = useNavigate();
    const params = useParams();

    // Get the video URL from the first element of video_items or use the fallback URL
    // let fallbackUrl = ""; // "https://innovators.blr1.cdn.digitaloceanspaces.com/wedding/wedding_invite_2_v.mp4";
    // let videoUrl = fallbackUrl;

    const [videoUrl, setVideoUrl] = useState(''); // Define videoUrl in state


    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("fetching data");
                const response = await VideoEngineAction.fetchSingleVideoEngineTemplates(params.templateId);
                console.log("response.data.content", response.data.content);
                let content = JSON.parse(response.data.content);
                console.log("content", content?.processing_artifacts?.data?.video_items);
                setConfigurationData(content);

                setVideoUrl(content?.processing_artifacts?.data?.video_items?.[0]?.properties?.video_url);
                console.log("videoUrl", videoUrl);
                console.log("videoUrl", videoUrl);

                // Capture frames for each video item after data is fetched
                content?.processing_artifacts?.data?.video_items.forEach((item, index) => {
                    captureFrameFromVideo(index, item.properties.start_time, item);
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [params.templateId]);

    const handleInputChange = (index, field, value, field_data_type) => {
        const updatedData = { ...configurationData };
        if (field_data_type == 'number') {
            updatedData.processing_artifacts.data.video_items[index].properties[field] = Number(value);
        } else {
            updatedData.processing_artifacts.data.video_items[index].properties[field] = value;
        }
        setConfigurationData(updatedData);

        // if (field === 'start_time') {
        //     captureFrameFromVideo(index, value, updatedData.processing_artifacts.data.video_items[index]);
        // }
        captureFrameFromVideo(index,
            updatedData.processing_artifacts.data.video_items[index].properties.start_time,
            updatedData.processing_artifacts.data.video_items[index]);
    };

    const handlePositionInputChange = (index, field, value, field_data_type) => {
        const updatedData = { ...configurationData };
        if (field_data_type == 'number') {
            updatedData.processing_artifacts.data.video_items[index].properties.position[field] = Number(value);
        } else {
            updatedData.processing_artifacts.data.video_items[index].properties.position[field] = value;
        }
        setConfigurationData(updatedData);

        // if (field === 'start_time') {
        //     captureFrameFromVideo(index, value, updatedData.processing_artifacts.data.video_items[index]);
        // }
        captureFrameFromVideo(index,
            updatedData.processing_artifacts.data.video_items[index].properties.start_time,
            updatedData.processing_artifacts.data.video_items[index]);
    };

    const captureFrameFromVideo = (index, time, item1) => {
        // const updatedData = { ...configurationData };
        const updatedData = JSON.parse(JSON.stringify(configurationData));
        const video = videoRefs.current[index];
        const canvas = canvasRefs.current[index];

        if (!video || !canvas) return;
        const context = canvas.getContext('2d');

        video.currentTime = time;
        video.onseeked = () => {
            // context.drawImage(video, 0, 0, canvas.width, canvas.height);
            // console.log("item", item);

            // if (!item) {
            //     const dataURL = canvas.toDataURL('image/png');
            //     setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            // } else {
            //     if (item.item_type == 'image') {
            //         const img = new Image();
            //         img.crossOrigin = "anonymous"; // Set crossOrigin attribute
            //         img.src = item.properties.image_url;
            //         img.onload = () => {
            //             context.drawImage(img, item.properties.position.x, item.properties.position.y, item.properties.width, item.properties.height);
            //             const dataURL = canvas.toDataURL('image/png');
            //             setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            //         };
            //     } else if (item.item_type == 'text') {

            //         let text = item.properties.text;
            //         let textWidth = item.properties.width; // context.measureText(text).width;
            //         let textHeight = item.properties.height; //item.properties.font_size;
            //         let textAlign = item.properties.text_align;
            //         if (!textAlign) textAlign = "center";
            //         let url1 = "https://generator.rajoffset.com/s2s/v1/text2-image?text=" + text + "&color=" + item["properties"]["color"] + "&artCode=simple&strokeWidth=0&strokeColor=red,black&fontSize=" + item["properties"]["font_size"] + "px&textAlign=" + textAlign + "&width=" + textWidth;

            //         const img = new Image();
            //         img.crossOrigin = "anonymous"; // Set crossOrigin attribute
            //         img.src = url1;
            //         img.onload = () => {
            //             context.drawImage(img, item.properties.position.x, item.properties.position.y);
            //             const dataURL = canvas.toDataURL('image/png');
            //             setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            //         };
            //     } else {
            //         const dataURL = canvas.toDataURL('image/png');
            //         setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
            //     }
            // }

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Filter items where time falls between start_time and end_time
            const itemsToDraw = updatedData.processing_artifacts.data.video_items.filter(
                item => time >= item.properties.start_time && time <= item.properties.end_time
            );

            // Draw each filtered item
            itemsToDraw.forEach(item => {
                if (item.item_type === 'image') {
                    const img = new Image();
                    img.crossOrigin = "anonymous"; // Set crossOrigin attribute
                    img.src = item.properties.image_url;
                    img.onload = () => {
                        context.drawImage(img, item.properties.position.x, item.properties.position.y, item.properties.width, item.properties.height);
                        const dataURL = canvas.toDataURL('image/png');
                        setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
                    };
                } else if (item.item_type === 'text') {
                    let text = item.properties.text;
                    let textWidth = item.properties.width; // context.measureText(text).width;
                    let textHeight = item.properties.height; //item.properties.font_size;
                    let textAlign = item.properties.text_align;
                    let fontName = item.properties.font || "Arial";
                    let FontWeight = item.properties.font_weight || "normal";
                    if (!textAlign) textAlign = "center";
                    let url1 = "https://generator.rajoffset.com/s2s/v1/text2-image?text=" + text + "&fontName=" + fontName + "&FontWeight=" + FontWeight + "&color=" + item["properties"]["color"] + "&artCode=simple&strokeWidth=0&strokeColor=red,black&fontSize=" + item["properties"]["font_size"] + "px&textAlign=" + textAlign + "&width=" + textWidth;

                    const img = new Image();
                    img.crossOrigin = "anonymous"; // Set crossOrigin attribute
                    img.src = url1;
                    img.onload = () => {
                        context.drawImage(img, item.properties.position.x, item.properties.position.y);
                        const dataURL = canvas.toDataURL('image/png');
                        setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
                    };
                }
            });

            const dataURL = canvas.toDataURL('image/png');
            setImageSrcs(prevState => ({ ...prevState, [index]: dataURL }));
        };
    };

    const addTextItem = (index) => {
        const updatedData = { ...configurationData };
        const dataAddIndex = updatedData.processing_artifacts.data.video_items[index];
        const newItem = {
            "item_type": "text",
            "properties": {
                "start_time": dataAddIndex.properties.start_time || 0,
                "end_time": dataAddIndex.properties.end_time || 0,
                "field_name": "",
                "text": "New Text To show",
                "font": "Arial",
                "font_size": 40,
                "font_weight": "normal",
                "color": "darkred",
                "width": 200,
                "height": 200,
                "position": {
                    "x": 100,
                    "y": 100
                },
                "rotation": 0,
                "start_animation": "fadein",
                "align": "center",
                "font_family": "",
                "background_color": "",
                "opacity": 0,
                "start_animation_end_time": 0,
                "end_animation": "",
                "end_animation_start_time": 0,
                "animation_iteration": 0,
                "animation_direction": ""
            }
        };


        updatedData.processing_artifacts.data.video_items.splice(index + 1, 0, newItem);
        setConfigurationData(updatedData);
    }

    const addImageItem = (index) => {
        const updatedData = { ...configurationData };
        const dataAddIndex = updatedData.processing_artifacts.data.video_items[index];
        const newItem = {
            "item_type": "image",
            "properties": {
                "start_time": dataAddIndex.properties.start_time || 0,
                "end_time": dataAddIndex.properties.end_time || 0,
                "image_url": "https://digital-invitation.s3.amazonaws.com/engine/other/lineart/77476_lineartA.png",
                "position": {
                    "x": 100,
                    "y": 100
                },
                "width": 200,
                "height": 200,
                "rotation": 0,
                "field_name": "",
                "opacity": 0,
                "start_animation": "",
                "start_animation_end_time": 0,
                "end_animation": "",
                "end_animation_start_time": 0,
                "animation_iteration": 0,
                "animation_direction": ""
            }
        };

        updatedData.processing_artifacts.data.video_items.splice(index + 1, 0, newItem);
        setConfigurationData(updatedData);
    }

    const duplicateItem = (index) => {
        const updatedData = { ...configurationData };
        const dataAtIndex = updatedData.processing_artifacts.data.video_items[index];

        updatedData.processing_artifacts.data.video_items.splice(index + 1, 0, dataAtIndex);
        setConfigurationData(updatedData);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log("Updated Configuration Data:", configurationData);
        const response = await VideoEngineAction.updateSingleVideoEngineTemplates(params.templateId, {
            content: JSON.stringify(configurationData)
        });
        console.log("response", response);
        toast.success("Configuration updated successfully");
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;



    return (
        <form onSubmit={handleSubmit}>
            {configurationData.processing_artifacts.data.video_items.map((item, index) => (
                <Grid container spacing={2} key={index} sx={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '20px' }}>
                    <Grid item xs={12} md={4}>
                        <Box>
                            {videoUrl.length > 0 && (
                                <>
                                    <video
                                        ref={el => videoRefs.current[index] = el}
                                        crossOrigin="anonymous"
                                        // src="https://innovators.blr1.cdn.digitaloceanspaces.com/ve/2024-10-20/542a142d-7845-43de-a407-5911b7c59ed8/final.mp4"
                                        src={videoUrl}
                                        style={{ display: 'none' }}
                                    />
                                    <canvas
                                        ref={el => canvasRefs.current[index] = el}
                                        width="720"
                                        height="1280"
                                        style={{ display: 'none' }}
                                    />
                                </>
                            )}

                            {imageSrcs[index] && (
                                <img
                                    src={imageSrcs[index]}
                                    alt="Captured frame"
                                    style={{ width: '100%', height: 'auto', backgroundColor: 'red' }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h6">Item {index + 1} ({item.item_type}) - Group: Video Items</Typography>

                        <TextField
                            size='small'
                            label="Start Time"
                            type="number"
                            value={item.properties.start_time}
                            onChange={(e) => handleInputChange(index, 'start_time', e.target.value, 'number')}
                            margin="normal"
                        />
                        <TextField
                            size='small'
                            label="End Time"
                            type="number"
                            value={item.properties.end_time}
                            onChange={(e) => handleInputChange(index, 'end_time', e.target.value, 'number')}
                            margin="normal"
                        />

                        <TextField
                            size='small'
                            label="Rotation"
                            type="number"
                            value={item.properties.rotation}
                            onChange={(e) => handleInputChange(index, 'rotation', e.target.value, 'number')}
                            margin="normal"
                        />

                        {item.item_type === 'video' && (
                            <>
                                <TextField
                                    size='small'
                                    label="Video URL"
                                    type="url"
                                    value={item.properties.video_url}
                                    fullWidth
                                    onChange={(e) => handleInputChange(index, 'video_url', e.target.value, 'string')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Clip Start Time"
                                    type="number"
                                    value={item.properties.clip_start_time}
                                    onChange={(e) => handleInputChange(index, 'clip_start_time', e.target.value, 'number')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Clip End Time"
                                    type="number"
                                    value={item.properties.clip_end_time}
                                    onChange={(e) => handleInputChange(index, 'clip_end_time', e.target.value, 'number')}
                                    margin="normal"
                                />
                            </>
                        )}

                        {item.item_type === 'image' && (
                            <>
                                <TextField
                                    size='small'
                                    label="Image URL"
                                    type="url"
                                    value={item.properties.image_url}
                                    onChange={(e) => handleInputChange(index, 'image_url', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />
                            </>
                        )}

                        {item.item_type === 'text' && (
                            <>
                                <TextField
                                    size='small'
                                    label="Text"
                                    type="text"
                                    value={item.properties.text}
                                    onChange={(e) => handleInputChange(index, 'text', e.target.value, 'string')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Font Size"
                                    type="number"
                                    value={item.properties.font_size}
                                    onChange={(e) => handleInputChange(index, 'font_size', e.target.value, 'number')}
                                    margin="normal"
                                />
                                <TextField
                                    size='small'
                                    label="Color"
                                    type="text"
                                    value={item.properties.color}
                                    onChange={(e) => handleInputChange(index, 'color', e.target.value, 'string')}
                                    margin="normal"
                                />
                            </>
                        )}

                        {/* <TextField
                            size='small'
                            label="Position X"
                            type="number"
                            value={item.properties.position.x}
                            onChange={(e) => handlePositionInputChange(index, 'x', e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            size='small'
                            label="Position Y"
                            type="number"
                            value={item.properties.position.y}
                            onChange={(e) => handlePositionInputChange(index, 'y', e.target.value)}
                            margin="normal"
                        /> */}

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Position X"
                                    type="number"
                                    value={item.properties.position.x}
                                    onChange={(e) => handlePositionInputChange(index, 'x', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Position Y"
                                    type="number"
                                    value={item.properties.position.y}
                                    onChange={(e) => handlePositionInputChange(index, 'y', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Width"
                                    type="number"
                                    value={item.properties.width}
                                    onChange={(e) => handleInputChange(index, 'width', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Height"
                                    type="number"
                                    value={item.properties.height}
                                    onChange={(e) => handleInputChange(index, 'height', e.target.value, 'number')}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>


                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Font Weight"
                                    type="number"
                                    value={item.properties.font_weight || 300}
                                    onChange={(e) => handleInputChange(index, 'font_weight', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Opacity"
                                    type="number"
                                    value={item.properties.opacity}
                                    onChange={(e) => handleInputChange(index, 'opacity', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Animation Direction"
                                    type="text"
                                    value={item.properties.animation_direction}
                                    onChange={(e) => handleInputChange(index, 'animation_direction', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    size='small'
                                    label="Animation Iteration"
                                    type="number"
                                    value={item.properties.animation_iteration}
                                    onChange={(e) => handleInputChange(index, 'animation_iteration', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>


                        <TextField
                            size='small'
                            label="Field Name"
                            type="text"
                            value={item.properties.field_name}
                            onChange={(e) => handleInputChange(index, 'field_name', e.target.value, 'string')}
                            margin="normal"
                            fullWidth
                        />
                        {/* <TextField
                            size='small'
                            label="Font Name"
                            type="text"
                            value={item.properties.font}
                            onChange={(e) => handleInputChange(index, 'font', e.target.value, 'string')}
                            margin="normal"
                            fullWidth
                        /> */}

                        <FormControl fullWidth margin="normal" size="small">
                            <InputLabel>Font Name</InputLabel>
                            <Select
                                value={item.properties.font}
                                onChange={(e) => handleInputChange(index, 'font', e.target.value)}
                            >
                                <MenuItem value="ParisienneRegular">En- ParisienneRegular</MenuItem>
                                <MenuItem value="AllessaPersonalUs">En- AllessaPersonalUs</MenuItem>
                                <MenuItem value="AulyarsItalic">En- AulyarsItalic</MenuItem>
                                <MenuItem value="AulyarsRegular">En- AulyarsRegular</MenuItem>
                                <MenuItem value="BigdeyDemo">En- BigdeyDemo</MenuItem>
                                <MenuItem value="BSCalligraphy">En- BSCalligraphy</MenuItem>
                                <MenuItem value="ChopinScript">En- ChopinScript</MenuItem>
                                <MenuItem value="ChristmasTimePersonalUse">En- ChristmasTimePersonalUse</MenuItem>
                                <MenuItem value="GreatDayPersonalUse">En- GreatDayPersonalUse</MenuItem>
                                <MenuItem value="HandycheeraRegular">En- HandycheeraRegular</MenuItem>
                                <MenuItem value="LucySaid">En- LucySaid</MenuItem>
                                <MenuItem value="MessengerPigeons">En- MessengerPigeons</MenuItem>
                                <MenuItem value="ILoveGlitter">En- ILoveGlitter</MenuItem>
                                <MenuItem value="Precious">En- Precious</MenuItem>
                                <MenuItem value="TheHeartofEverything">En- TheHeartofEverything</MenuItem>
                                <MenuItem value="WinterCalligraphy">En- WinterCalligraphy</MenuItem>
                                <MenuItem value="Aaleyah">En- Aaleyah</MenuItem>
                                <MenuItem value="MotherTongue">En- MotherTongue</MenuItem>
                                <MenuItem value="AgendaKing">En- AgendaKing</MenuItem>
                                <MenuItem value="AlexBrushRegular">En- AlexBrushRegular</MenuItem>
                                <MenuItem value="Andhyta">En- Andhyta</MenuItem>
                                <MenuItem value="BacktoBlackBold">En- BacktoBlackBold</MenuItem>
                                <MenuItem value="BubbleLove">En- BubbleLove</MenuItem>
                                <MenuItem value="BullettoKilla">En- BullettoKilla</MenuItem>
                                <MenuItem value="ChocolateBar">En- ChocolateBar</MenuItem>
                                <MenuItem value="CocogooseNarrowRegularItalic">En- CocogooseNarrowRegularItalic</MenuItem>
                                <MenuItem value="FOFBB">En- FOFBB</MenuItem>
                                <MenuItem value="FOFBB_ITALIC">En- FOFBB_ITALIC</MenuItem>
                                <MenuItem value="Freakshow">En- Freakshow</MenuItem>
                                <MenuItem value="FROSTY">En- FROSTY</MenuItem>
                                <MenuItem value="Gaby">En- Gaby</MenuItem>
                                <MenuItem value="HughisLife">En- HughisLife</MenuItem>
                                <MenuItem value="LoveLetters">En- LoveLetters</MenuItem>
                                <MenuItem value="mexcellent3d">En- mexcellent3d</MenuItem>
                                <MenuItem value="MISTV">En- MISTV</MenuItem>
                                <MenuItem value="musicals">En- musicals</MenuItem>
                                <MenuItem value="MySunshine">En- MySunshine</MenuItem>
                                <MenuItem value="Natyl">En- Natyl</MenuItem>
                                <MenuItem value="PleaseDonotTakeMyMan">En- PleaseDonotTakeMyMan</MenuItem>
                                <MenuItem value="Regista">En- Regista</MenuItem>
                                <MenuItem value="Streetwear">En- Streetwear</MenuItem>
                                <MenuItem value="VirgiEllaRegular">En- VirgiEllaRegular</MenuItem>
                                <MenuItem value="SaralaBold">Hi- SaralaBold</MenuItem>
                                <MenuItem value="SaralaRegular">Hi- SaralaRegular</MenuItem>
                                <MenuItem value="NotoBold">Hi- NotoBold</MenuItem>
                                <MenuItem value="NotoBoldItalic">Hi- NotoBoldItalic</MenuItem>
                                <MenuItem value="NotoItalic">Hi- NotoItalic</MenuItem>
                                <MenuItem value="NotoRegular">Hi- NotoRegular</MenuItem>
                                <MenuItem value="Alkatra">Hi- Alkatra</MenuItem>
                                <MenuItem value="BakbakOne">Hi- BakbakOne</MenuItem>
                                <MenuItem value="Modak">Hi- Modak</MenuItem>
                                <MenuItem value="PalanquinDarkBold">Hi- PalanquinDarkBold</MenuItem>
                                <MenuItem value="PalanquinDarkMedium">Hi- PalanquinDarkMedium</MenuItem>
                                <MenuItem value="PalanquinDarkRegular">Hi- PalanquinDarkRegular</MenuItem>
                                <MenuItem value="PalanquinDarkSemiBold">Hi- PalanquinDarkSemiBold</MenuItem>
                                <MenuItem value="TiroDevanagariSanskritItalic">Hi- TiroDevanagariSanskritItalic</MenuItem>
                                <MenuItem value="TiroDevanagariSanskritRegular">Hi- TiroDevanagariSanskritRegular</MenuItem>
                                <MenuItem value="YatraOne">Hi- YatraOne</MenuItem>


                            </Select>
                        </FormControl>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Start Animation"
                                    type="text"
                                    value={item.properties.start_animation}
                                    onChange={(e) => handleInputChange(index, 'start_animation', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="Start Animation End Time"
                                    type="number"
                                    value={item.properties.start_animation_end_time}
                                    onChange={(e) => handleInputChange(index, 'start_animation_end_time', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="End Animation"
                                    type="text"
                                    value={item.properties.end_animation}
                                    onChange={(e) => handleInputChange(index, 'end_animation', e.target.value, 'string')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    label="End Animation Start Time"
                                    type="number"
                                    value={item.properties.end_animation_start_time}
                                    onChange={(e) => handleInputChange(index, 'end_animation_start_time', e.target.value, 'number')}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => addTextItem(index)}
                                    fullWidth
                                >
                                    Add Text
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => addImageItem(index)}
                                    fullWidth
                                >
                                    Add Image
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => duplicateItem(index)}
                                    fullWidth
                                >
                                    Duplicate
                                </Button>
                            </Grid>



                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => {
                                        console.log("Move item to up in array", index);
                                        if (index === 0) return;
                                        const updatedData = { ...configurationData };
                                        const temp = updatedData.processing_artifacts.data.video_items[index];
                                        updatedData.processing_artifacts.data.video_items[index] = updatedData.processing_artifacts.data.video_items[index - 1];
                                        updatedData.processing_artifacts.data.video_items[index - 1] = temp;
                                    }}
                                    fullWidth
                                >
                                    Move Up
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => {
                                        console.log("Move item to up in array", index);
                                        if (index === 0) return;
                                        const updatedData = { ...configurationData };
                                        const temp = updatedData.processing_artifacts.data.video_items[index];
                                        updatedData.processing_artifacts.data.video_items[index] = updatedData.processing_artifacts.data.video_items[index + 1];
                                        updatedData.processing_artifacts.data.video_items[index + 1] = temp;
                                    }}
                                    fullWidth
                                >
                                    Move Down
                                </Button>
                            </Grid>

                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Label />}
                                    onClick={() => {
                                        const updatedData = { ...configurationData };
                                        updatedData.processing_artifacts.data.video_items.splice(index, 1);
                                        setConfigurationData(updatedData);
                                    }}
                                    fullWidth
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>




                    </Grid>
                </Grid>
            ))}
            <Button type="submit" variant="contained" color="primary" sx={{ padding: '10px 20px', fontSize: '16px' }}>
                Save
            </Button>
        </form>
    );
};


export default VideoEngineConfigurationEdit;