
import React, { useState } from 'react';

const VideoEngineTemplateEdit = () => {
    const [templateName, setTemplateName] = useState('');
    const [description, setDescription] = useState('');

    const handleSave = () => {
        // Logic to save the template
        console.log('Template saved:', { templateName, description });
    };

    return (
        <div>
            <h1>Edit Video Engine Template</h1>
            <form onSubmit={(e) => e.preventDefault()}>
                <div>
                    <label>Template Name:</label>
                    <input
                        type="text"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                    />
                </div>
                <div>
                    <label>Description:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <button type="button" onClick={handleSave}>
                    Save
                </button>
            </form>
        </div>
    );
};

export default VideoEngineTemplateEdit;