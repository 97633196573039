import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchDownloadsRequest = () => ({
    type: actionTypes.FETCH_DOWNLOAD_REQUEST,
});
export const fetchDownloadsSuccess = (data) => ({
    type: actionTypes.FETCH_DOWNLOAD_SUCCESS,
    payload: data,
});
export const fetchDownloadsFailure = (error) => ({
    type: actionTypes.FETCH_DOWNLOAD_FAILURE,
    payload: error,
});

export const fetchDownloadsData = (page, limit) => {

    return async (dispatch) => {
        dispatch(fetchDownloadsRequest());
        try {
            const {data} = await api.fetchDownloads(page, limit);
            dispatch(fetchDownloadsSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchDownloadsFailure(error));
        }
    };
};
