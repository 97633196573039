import * as actionTypes from '../actionTypes.js';

const initialState = {
    cardFromFieldOptionsData: null,
    loading: false,
    error: null,
};

const cardFromFieldOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CARD_FROM_FIELD_OPTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CARD_FROM_FIELD_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                cardFromFieldOptionsData: action.payload,
            };
        case actionTypes.FETCH_CARD_FROM_FIELD_OPTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_SUCCESS:
            state.cardFromFieldOptionsData.data.rows = [...state.cardFromFieldOptionsData?.data?.rows, action.payload];
            return {
                ...state,
                loading: false,
                cardFromFieldOptionsData: state.cardFromFieldOptionsData,
            };
        case actionTypes.CREATE_CARD_FROM_FIELD_OPTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_SUCCESS:
            state.cardFromFieldOptionsData.data.rows = state.cardFromFieldOptionsData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return {
                ...state,
                loading: false,
                cardFromFieldOptionsData: state.cardFromFieldOptionsData
            }

        case actionTypes.UPDATE_CARD_FROM_FIELD_OPTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state
    }
};
export default cardFromFieldOptionsReducer;