import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Avatar } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderCountData } from '../../redux/actions/orderCountActions';

function Dashboardgrid() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.orderCount?.data);
    const [loading, setLoading] = useState(true);

    console.log(data);

    useEffect(() => {
        const fetchOrderCount = async () => {
            setLoading(true);
            await dispatch(fetchOrderCountData());
            setLoading(false);
        };
        fetchOrderCount();
    }, [dispatch]);


    return (
        <>
            <Typography variant="h2" color="initial">Welcome to Internal Portal</Typography>
           
        </>

    );
}

export default Dashboardgrid;

function BoxWrapper({ children }) {
    return (
        <Paper elevation={3} sx={{ borderRadius: 2, flex: 1, borderColor: 'grey.200', borderStyle: 'solid', borderWidth: 1 }}>
            {children}
        </Paper>
    );
}
