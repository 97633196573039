import React from 'react';
import { Grid,TextField,Typography,FormControl,InputLabel,Select,MenuItem,RadioGroup,FormControlLabel,Radio,Paper,Box,} from '@mui/material';

const PoliticalUpload = () => {
    return (
        <>
            <Grid container sx={{ marginLeft: -2 }}>
                <Grid item xs={12} sm={8} md={8}>
                    <Paper
                        elevation={3}
                        sx={{
                            maxWidth: '1000px',
                            margin: 'auto',
                            padding: '20px',
                            backgroundColor: '#f7f7f7',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ textAlign: 'left' }}>Political Auto Upload New Design [1 Image Format]</Typography>
                                <Typography variant="subtitle2" sx={{ textAlign: 'left', fontWeight: 'bold', color: '#f44336' }}>
                                    Please select Images Carefully, as it will be immediately automatically uploaded.
                                </Typography>
                            </Grid>

                            {/* Political Party and Template Category */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Political Party</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,  
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="BJP">BJP</MenuItem>
                                        <MenuItem value="Congress">Congress</MenuItem>
                                        <MenuItem value="APP">APP</MenuItem>
                                        <MenuItem value="SP">SP</MenuItem>
                                        <MenuItem value="Apna Dal">Apna Dal</MenuItem>
                                        <MenuItem value="JDU">JDU</MenuItem>
                                        <MenuItem value="RJD">RJD</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Template Category</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,  
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="category1">Category 1</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            {/* Template Name and Page Size */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Template Name"
                                    variant="standard"  
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Page Size</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,  
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="A4">A4</MenuItem>
                                        <MenuItem value="A5">A5</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            {/* Template Description */}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Template Description"
                                    variant="standard"
                                    rows={4}
                                />
                            </Grid>


                            {/* Is Occasion Date Applicable */}
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                                    <Typography>Is Occasion Date Applicable *</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup row defaultValue="no">
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>

        </>
    );
};

export default PoliticalUpload;