import React from 'react';
import { Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem,  Paper, Box, } from '@mui/material';

const ForegroundBackgroundImageUpload = () => {
    return (
        <>
            <Grid container sx={{ marginLeft: -2 }}>
                <Grid item xs={12} sm={8} md={8}>
                    <Paper
                        elevation={3}
                        sx={{
                            maxWidth: '1000px',
                            margin: 'auto',
                            padding: '20px',
                            backgroundColor: '#f7f7f7',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ textAlign: 'left' }}>Background & Foreground Seperate Uploader</Typography>
                                <Typography variant="subtitle2" sx={{ textAlign: 'left', fontWeight: 'bold', color: '#f44336' }}>
                                    Click on Upload Once Done
                                </Typography>
                            </Grid>

                            {/* Political Party and Template Category */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Common Template Name"
                                    variant="standard"  
                                    required
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard" required>
                                    <InputLabel>Page Size</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="category1">Category 1</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                        <MenuItem value="category2">Category 2</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                             {/*Common Template Description */}
                             <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Common Template Description"
                                    variant="standard"
                                    rows={4}
                                />
                            </Grid>
                           


                            {/* Template Name and Page Size */}
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Target Template Category</InputLabel>
                                    <Select
                                        defaultValue=""
                                        disableUnderline={false}
                                        sx={{ textAlign: 'left', marginBottom: 4 }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="A4">A4</MenuItem>
                                        <MenuItem value="A5">A5</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                           
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>

        </>
    );
};

export default ForegroundBackgroundImageUpload;