import * as actionTypes from '../actionTypes.js';

const initialState = {
    bundleData: null,
    loading: false,
    error: null,
};

const bundleDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_BUNDLE_DATA_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_BUNDLE_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                bundleData: action.payload,
            };
        case actionTypes.FETCH_BUNDLE_DATA_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload, 
            }
        default:
            return state;
    }
};

export default bundleDataReducer;