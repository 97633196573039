import * as actionTypes from '../actionTypes.js';

const initialState = {
    cardSampleData: null,
    loading: false,
    error: null,
};

const cardSampleReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_CARD_SAMPLE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CARD_SAMPLE_SUCCESS:
            return {
                ...state,
                loading: false,
                cardSampleData: action.payload,
            };
        case actionTypes.FETCH_CARD_SAMPLE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARD_SAMPLE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CREATE_CARD_SAMPLE_SUCCESS:
            state.cardSampleData.data.rows = [...state.cardSampleData?.data?.rows, action.payload]
            return{
                ...state,
                loading: false,
                cardSampleData: state.cardSampleData,
            };
        case actionTypes.CREATE_CARD_SAMPLE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPDATE_CARD_SAMPLE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPDATE_CARD_SAMPLE_SUCCESS:
            state.cardSampleData.data.rows = state.cardSampleData?.data?.rows.map(card =>
                card.id === action.payload.id ? action.payload : card)
            return{
                ...state,
                loading: false,
                cardSampleData: state.cardSampleData
            };
        case actionTypes.UPDATE_CARD_SAMPLE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.DELETE_CARD_SAMPLE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_CARD_SAMPLE_SUCCESS:
            state.cardSampleData.data.rows = state.cardSampleData?.data?.rows.filter(card => card.id !== action.payload )
            return{
                ...state,
                loading: false,
                cardSampleData: state.cardSampleData,
            };
        case actionTypes.DELETE_CARD_SAMPLE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.GET_SINGLE_CARD_SAMPLE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_SINGLE_CARD_SAMPLE_SUCCESS:
            return{
                ...state,
                cardSampleData: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_SINGLE_CARD_SAMPLE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state
    }
};
export default cardSampleReducer;