import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOG_OUT } from '../../redux/actionTypes';
import { Box, useMediaQuery } from '@mui/material';

import ConfirmationModal from '../modals/ConfirmationModal';
import TopNavigation from './TopNavigation';
import SideNavigation from './SideNavigation';

const Navigation = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [isNavExpanded, setIsNavExpanded] = useState(JSON.parse(localStorage.getItem("navExpanded")));

    // Media query to detect mobile screens
    const isMobile = useMediaQuery('(max-width:600px)');

    // === Log out handler ===
    const handleLogout = () => {
        dispatch({ type: LOG_OUT });
    };

    return (
        <Box className="nav" sx={{ overflowX: 'hidden' }}>
            <ConfirmationModal
                open={confirmationModalOpen}
                setOpen={setConfirmationModalOpen}
                title={"Log out"}
                onConfirm={handleLogout}
            />

            <Box display={"flex"}
                sx={{
                    height: "100vh",
                    overflowX: 'hidden', // Prevent bottom scrollbar
                }}>
                <SideNavigation
                    currentPage={0}
                    isNavExpanded={isNavExpanded}
                    setIsNavExpanded={setIsNavExpanded}
                />

                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                        height: "100vh", 
                        width: isNavExpanded ? "calc(100% - 200px)" : "100%",
                        overflowX: 'hidden', 
                        overflowY: 'auto',  
                    }}>
                        
                    <TopNavigation />
                    <Box
                        padding={isMobile ? "30px 0 0 0" : "30px"} 
                        sx={{
                            height: "100%",
                            backgroundColor: "#F2F3F5",
                            borderRadius: isMobile ? "0px" : "16px", 
                            border: isMobile ? "none" : "1px solid #E0E0E0", 
                            overflowY: "auto", 
                            overflowX: "hidden", 
                            boxShadow: !isMobile && "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                            margin: "0",  
                        }}>
                        <Outlet sx={{ overflowX: 'hidden' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Navigation;
