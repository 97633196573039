import React from 'react';
import { Box } from '@mui/material';
import DashboardGrid from './DashboardGrid';

function Dashboard() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <DashboardGrid /> 
    </Box>
  );
}

export default Dashboard;
