import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchCardFromFieldRequest = () => ({
    type: actionTypes.FETCH_CARD_FROM_FIELD_REQUEST,
});
export const fetchCardFromFieldSuccess = (data) => ({
    type: actionTypes.FETCH_CARD_FROM_FIELD_SUCCESS,
    payload: data,
});
export const fetchCardFromFieldFailure = (error) => ({
    type: actionTypes.FETCH_CARD_FROM_FIELD_FAILURE,
    payload: error,
});

export const fetchCardFromFieldData = (card_id) => {

    return async (dispatch) => {
        console.log("step 1 inside cards Action")
        dispatch(fetchCardFromFieldRequest());
        try {
            const {data} = await api.cardFromField(card_id);
            console.log("inside cards action", data);
            dispatch(fetchCardFromFieldSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardFromFieldFailure(error));
        }
    };
};

export const createCardFromFieldRequest = () => ({
    type: actionTypes.CREATE_CARD_FROM_FIELD_REQUEST,
});
export const createCardFromFieldSuccess = (data) => ({
    type: actionTypes.CREATE_CARD_FROM_FIELD_SUCCESS,
    payload: data
});
export const createCardFromFieldFailure = (error) => ({
    type: actionTypes.CREATE_CARD_FROM_FIELD_FAILURE,
    payload: error,
});

export const createCardFromFieldeData = (newCardData) => {
    return async (dispatch) => {
        dispatch(createCardFromFieldRequest());
        try{
            const {data} = await api.createCardFromField(newCardData);
            dispatch(createCardFromFieldSuccess(data));
            return data;
        }catch(error) {
            dispatch(createCardFromFieldFailure(error));
        }
    }
};

export const updateCardFromFieldRequest = () => ({
    type: actionTypes.UPDATE_CARD_FROM_FIELD_REQUEST,
});
export const updateCardFromFieldSuccess = (data) => ({
    type: actionTypes.UPDATE_CARD_FROM_FIELD_SUCCESS,
    payload: data,
});
export const updateCardFromFieldFailure = (error) => ({
    type: actionTypes.UPDATE_CARD_FROM_FIELD_FAILURE,
    payload: error,
});

export const updateCardFromFieldData = (updateData) => {
    return async (dispatch) => {
        dispatch(updateCardFromFieldRequest());
        try{
            const response = await api.updateCardFromField(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updateCardFromFieldSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updateCardFromFieldFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deleteCardFromFieldRequest = () => ({
    type: actionTypes.DELETE_CARD_FROM_FIELD_REQUEST,
});
export const deleteCardFromFieldSuccess = (id) => ({
    type: actionTypes.DELETE_CARD_FROM_FIELD_SUCCESS,
    payload: id,
});
export const deleteCardFromFieldFailure = (error) => ({
    type: actionTypes.DELETE_CARD_FROM_FIELD_FAILURE,
    payload: error,
});

export const deleteCardFromFieldData = (id) => {
    return async (dispatch) => {
        dispatch(deleteCardFromFieldRequest());
        try{
            await api.deleteCardFromField(id);
            dispatch(deleteCardFromFieldSuccess(id));
        } catch(error) {
            dispatch(deleteCardFromFieldFailure(error));
        }
    };
};