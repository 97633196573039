import axios from "axios";

const API = axios.create({
    baseURL: `https://account.rajoffset.com/`,
    // baseURL: `http://localhost:3000/`,
});

API.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
    }
    return req;
});

API.interceptors.response.use((response) => {

    return response;
}, (error) => {
    if (error.response && error.response?.data) {
        return Promise.reject(error.response?.data);
    }
    return Promise.reject(error.message);
})

const EnginePublicAPI = axios.create({
    baseURL: `https://engine.rajoffset.com/`,
    // baseURL: `http://localhost:6000/`,
});


const APIEngine = axios.create({
    baseURL: `https://engine.rajoffset.com/`,
    // baseURL: `http://localhost:6000/`,
});

APIEngine.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
        console.log("Access token found", JSON.parse(localStorage.getItem("access_token")));
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
    } else {
        console.log("No access token found")
    }
    return req;
});

APIEngine.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response?.data) {
            return Promise.reject(error.response?.data);
        }
        return Promise.reject(error.message);
    }
);
export const login = (form) => API.post(`internal-api-v2/auth/login/`, form);
export const verifyOtp = (form) => API.post(`internal-api-v2/auth/login-verify-otp/`, form);

export const orderCount = () => API.get(`/crm/dashboard/v1/order-count`);

export const cardGroup = (page, limit, search) => APIEngine.get(`/management/invitation/group/v1/?page=${page}&limit=${limit}&search=${search}`);
export const createCardGroup = (form) => APIEngine.post(`/management/invitation/group/v1/`, form);
export const editCardGroup = (id, form) => APIEngine.put(`/management/invitation/group/v1/${id}`, form);
export const deleteCardGroup = (id) => APIEngine.delete(`/management/invitation/group/v1/${id}`);

export const cards = (page, limit, search,groupId) => APIEngine.get(`/management/invitation/card/v1/?page=${page}&limit=${limit}&search=${search}&group_id=${groupId}`);
export const duplicateCard = (card_id) => APIEngine.get(`/management/invitation/card-duplicate/v1/${card_id}`);
export const mapTemplateInCard = (card_id, template_id) => APIEngine.post(`/management/invitation/card-map/v1/${card_id}`, { template_id: template_id });

export const createCards = (form) => APIEngine.post(`/management/invitation/card/v1/`, form);
export const updateCards = (id, form) => APIEngine.put(`/management/invitation/card/v1/${id}`, form);
export const deleteCards = (id) => APIEngine.delete(`/management/invitation/card/v1/${id}`);

export const cardSample = (form) => APIEngine.get(`/management/invitation/card-sample/v1/`, form);
export const createCardSample = (form) => APIEngine.post(`/management/invitation/card-sample/v1/`, form);
export const updateCardSample = (id, form) => APIEngine.put(`/management/invitation/card-sample/v1/${id}`, form);
export const deleteCardSample = (id) => APIEngine.delete(`/management/invitation/card-sample/v1/${id}`);
export const getSingleCardSample = (id, form) => APIEngine.get(`/invitation/card/v1/${id}`, form);

export const cardFromField = (card_id) => APIEngine.get(`/management/invitation/card-form_field/v1/?card_id=${card_id}`);
export const createCardFromField = (form) => APIEngine.post(`/management/invitation/card-form_field/v1/`, form);
export const updateCardFromField = (id, form) => APIEngine.put(`/management/invitation/card-form_field/v1/${id}`, form);
export const deleteCardFromField = (id) => APIEngine.delete(`/management/invitation/card-form_field/v1/${id}`);

export const cardFromFieldOptions = () => APIEngine.get(`/management/invitation/card-form-field-options/v1/`)
export const createCardFromFieldOptions = (form) => APIEngine.post(`/management/invitation/card-form-field-options/v1/`, form);
export const updateCardFromFieldOptions = (id, form) => APIEngine.put(`/management/invitation/card-form-field-options/v1/${id}`, form);
export const deleteCardFromFieldOptions = (id) => APIEngine.delete(`/management/invitation/card-form-field-options/v1/${id}`);

export const cardEntry = (page, limit, search) => APIEngine.get(`/management/invitation/card-entry/v1/?page=${page}&limit=${limit}&search=${search}`);
export const createCardEntry = (form) => APIEngine.post(`/management/invitation/card-entry/v1/`, form);
export const updateCardEntry = (id, form) => APIEngine.put(`/management/invitation/card-entry/v1/${id}`, form);
export const deleteCardEntry = (id) => APIEngine.delete(`/management/invitation/card-entry/v1/${id}`);

export const cardEntryValues = (form) => APIEngine.get(`/management/invitation/card-entry-field/v1/`, form);
export const createCardEntryValues = (form) => APIEngine.post(`/management/invitation/card-entry-field/v1/`, form);
export const updateCardEntryValues = (id, form) => APIEngine.put(`/management/invitation/card-entry-field/v1/${id}`, form);
export const deleteCardEntryValues = (id) => APIEngine.delete(`/management/invitation/card-entry-field/v1/${id}`);

export const fetchDownloads = (page, limit) => API.get(`/management/my-downloads/v1/?page=${page}&limit=${limit}`);
export const fetchTemplateRecords = () => APIEngine.get(`/management/category/v1/?access=developer`);



export const getAllVideoEngineTemplates = (page, limit) => APIEngine.get(`/management/video-engine/v1/?page=${page}&limit=${limit}`);
export const getSingleVideoEngineTemplates = (templateId) => APIEngine.get(`/management/video-engine/v1/${templateId}`);
export const updateSingleVideoEngineTemplates = (templateId, form) => APIEngine.put(`/management/video-engine/v1/${templateId}`, form);
export const generateSampleOfSingleVideoEngineTemplates = (templateId, form) => APIEngine.get(`/management/invitation/video-card-sample-auto-sample-generate/v1/${templateId}`);


//video-card-sample-auto-sample-generate
{/*
Request URL:       https://engine.rajoffset.com/management/new-engine/v1
Request Method:    POST
*/}

export const getSingleCardDetails = (card_id, group_path, group_type) => APIEngine.get(`/invitation/card/v1/${card_id}/?group_path=${group_path}&group_type=${group_type}`);
export const createCardForCustomer = (id, form) => APIEngine.post(`/invitation/card/v1/${id}`, form);


export const createCardForDiwaliOffer = (form) => APIEngine.post(`/invitation/multiple-card/v1/`, form);

export const getBundles = (page, limit) => APIEngine.get(`/management/invitation/multi-card-entry/v1/?page=${page}&limit=${limit}`);
export const createBundle = (id, form) => APIEngine.post(`/management/invitation/multi-card-entry-payment-status-change/v1/${id}`,form);






